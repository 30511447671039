<template>
  <div id="app">
    <router-view />
    <!-- <tabbar v-if="$route.meta.showFooter" :isShow="isShow" /> -->
  </div>
</template>
<script>

export default {
  name: "App",
  components: {
    // tabbar
  },
  data() {
    return {
    }
  },

}
</script>

<style lang="less">
* {
  margin: 0;
  padding: 0;
}
html {
  height: 100%;
}

body {
  height: 100%;
  // overflow: hidden;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}
</style>
