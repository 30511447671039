//域名统一管理

const BASEURL = {
    // devUrl: 'http://onlineprescription-api.yaojixin.com:8090/',
    // productUrl: 'http://test-onlineprescription-yunying.yaojixin.com:8080',
    // productUrl: 'http://172.18.63.20:8080'
    devUrl: 'http://192.168.0.104:9500', // 测试地址
    testUrl: 'http://test-yaoyijia-api.yaojixin.com', // 测试地址
    // productUrl: 'http://120.24.75.216:8090/', // 测试地址
    // productUrl: 'http://172.18.63.37:8090' // 生产地址
    productUrl: 'https://yaoyijia-api.yaojixin.com' // 生产地址
}
let devUrl = ''
switch (process.env.NODE_ENV) {
    case 'development': // dev 本地
        devUrl = BASEURL.devUrl; //本地域名
        break
    case 'test':   // 生产测试
        devUrl = BASEURL.testUrl;
        break
    case 'production': // 生产
        devUrl = BASEURL.productUrl;
        break
}
// export default url;
// console.log(process.env)
module.exports = {
    devUrl: devUrl,
}
// export default BASEURL;
