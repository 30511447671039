import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/login',
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login.vue'),
    meta: { title: '登录' }
  },
  {
    path: '/history_data_ykf',
    name: 'history_data_ykf',
    component: () => import('../views/pages/history_data_ykf.vue'),
    meta: { title: '更多数据' }
  },
  {
    path: '/history_data_oto',
    name: 'history_data_oto',
    component: () => import('../views/pages/history_data_oto.vue'),
    meta: { title: '更多数据' }
  },
  {
    path: '/history_data_cf',
    name: 'history_data_cf',
    component: () => import('../views/pages/history_data_cf.vue'),
    meta: { title: '更多数据' }
  },
  {
    path: '/sale_detail',
    name: 'sale_detail',
    component: () => import('../views/pages/sale_detail.vue'),
    meta: { title: '药品销售明细' }
  },
  {
    path: '/person_info',
    name: 'person_info',
    component: () => import('../views/person_info.vue'),
    meta: { title: '个人信息' }
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('../views/home.vue'),
    meta: { title: '首页' }
  },
  {
    path: '/index',
    name: 'index',
    component: () => import('../views/index.vue'),
    meta: { title: '首页' }
  },
  {
    path: '/changePassword',
    name: 'changePassword',
    component: () => import('../views/changePassword.vue'),
    meta: { title: '密码设置' }
  },
]
//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
