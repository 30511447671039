// post和get请求的封装
import axios from './request';
import BASE_URL from './base';
import { generateSign } from '@/utils/util'
/**
 * 请求封装
 * @desc注册请求
 * @param {String} method [GET或POST]
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
console.log('process.env.NODE_ENV', process.env.NODE_ENV);
let configUrl = '';
export default function $http(method, url, params) {  //走本地接口
    configUrl = BASE_URL.devUrl;
    let opt = {
        url: process.env.NODE_ENV === 'development' ? `/dev_api${url}` : `${configUrl}${url}`,
        // url: `/dev_api${url}`,
        method: method
    }
    let keyName = method.toLocaleUpperCase() === 'GET' ? 'params' : 'data';
    opt[keyName] = params;
    return axios(opt);
};
// export function $http_get(url, params) {
//     return axios({
//         url: `${configUrl}${url}`,
//         method: 'get',
//         params: params,
//     });
// };
